import { CopyOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Card, Flex, message, Popover, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";
import useCheckDemoPath from "../../hooks/app.hook";
import { useAppSelector } from "../../hooks/redux-hooks";
import {
  getNotesDetails,
  handleGenerateTreatmentPlan,
  updateNote,
} from "../../service/notes.service";
import { EventType, NoteSection, trackEvent } from "../../utils/analytics";
import { MAX_POILING_TIME_OUT } from "../../utils/constants";
import { copySectionContent } from "../../utils/datamanipulation.utils";
import styles from "./notes.module.scss";
import { NoteType } from "../../domain/notes.domain";

const NudgeTreatmentPlan = () => {
  const componentName = "notes";
  const checkDemoPath = useCheckDemoPath();
  const [expandedSessionTreatmentPlan, setExpandedSessionTreatmentPlan] =
    React.useState(false);
  const [editTreatmentPlan, setEditTreatmentPlan] = React.useState(false);
  const [isOldTreatmentPlan, setIsOldTreatmentPlan] = React.useState(false);
  const [sessionTreatmentPlanContent, setSessionTreatmentPlanContent] =
    React.useState("");

  const { isDemoPath } = useAppSelector((state) => state.appState);
  const { notesDetails } = useAppSelector((state) => state.notes);

  const handleGenerateNewTreatmentPlan = async () => {
    if (checkDemoPath()) {
      return;
    }
    trackEvent(EventType.GENERATE_SESSION_PLAN, {
      recordingId: notesDetails?.id,
    });

    setIsOldTreatmentPlan(false);
    handleGenerateTreatmentPlan(notesDetails?.id)
      .then((result) => {
        setSessionTreatmentPlanContent(result.data["treatment_plan"]);
      })
      .catch((error) => {
        console.error(error);
        setIsOldTreatmentPlan(true);
        message.error("Error generating treatment plan. Please try again.");
      })
      .finally(() => {});
  };

  const updateTreatmentPlan = () => {
    updateNote({
      audio_id: notesDetails?.id,
      treatment_plan: sessionTreatmentPlanContent,
    })
      .then((result) => {
        message.success("Treatment plan saved successfully");
        setEditTreatmentPlan(false);
        setSessionTreatmentPlanContent(result.data["treatment_plan"]);
      })
      .catch((error) => {
        message.error("Error saving treatment plan");
        console.log(error);
      });
  };

  const pollSessionTreatmentPlan = async () => {
    try {
      const response = await getNotesDetails(notesDetails?.id);
      const data = response.data;

      if (data.treatment_plan) {
        setSessionTreatmentPlanContent(data.treatment_plan);
        return;
      }

      // If not done processing insights then do the poll.
      const currentDate = new Date();
      const genNoteEndDate = new Date(data.stats_generate_note_end_at);
      if (currentDate.getTime() - genNoteEndDate.getTime() <= MAX_POILING_TIME_OUT) {
        setTimeout(() => pollSessionTreatmentPlan(), 3000);
      } else {
        setIsOldTreatmentPlan(true);
      }
    } catch (error) {
      console.error("Error checking audio status:", error);
    }
  };

  // biome-ignore lint: no need to add pollSessionTreatmentPlan to the dependency array
  React.useEffect(() => {
    const currentDate = new Date();
    const genNoteEndDate = notesDetails
      ? new Date(notesDetails.stats_generate_note_end_at)
      : null;
    const isWithinTimeout =
      genNoteEndDate &&
      currentDate.getTime() - genNoteEndDate.getTime() <= MAX_POILING_TIME_OUT;

    if (notesDetails?.id) {
      if (
        notesDetails.note_type === NoteType.Intake ||
        notesDetails.note_type === NoteType.SOL_PSYCH_INTAKE ||
        notesDetails.note_type === NoteType.THERAPY_INTAKE
      ) {
        if (!notesDetails.treatment_plan) {
          pollSessionTreatmentPlan();
        } else {
          setSessionTreatmentPlanContent(notesDetails.treatment_plan);
        }
      } else if (isWithinTimeout) {
        setTimeout(() => pollSessionTreatmentPlan(), 3000);
      } else {
        setIsOldTreatmentPlan(true);
      }
    } else if (isWithinTimeout) {
      setTimeout(() => pollSessionTreatmentPlan(), 3000);
    } else {
      setIsOldTreatmentPlan(true);
    }
  }, [notesDetails]);

  if (!notesDetails) {
    return null;
  }

  return (
    <Flex gap={10} vertical>
      <Flex
        style={{
          width: "100%",
          marginBottom: "20px",
        }}
        gap={10}
      >
        <Card
          id="special-card"
          hoverable
          title={
            <Flex align="center" gap={10}>
              Nudge Treatment Plan{" "}
            </Flex>
          }
          extra={
            <Popover
              placement="left"
              title={"Nudge Treatment Plan"}
              content={
                <>
                  Nudge Treatment Plan is a powerful tool designed to assist
                  <br />
                  therapists in creating a treatment plan post intake session.
                  <br />
                  <br />
                  Our treatment plans are still in beta and will improve over
                  time.
                  <br />
                  We urge you to treat our treatment plan as a recommendation
                  and
                  <br />
                  use your best judgement as you know your client the best.
                  <br />
                </>
              }
            >
              <span
                className={`${styles[`${componentName}__main-section-span`]}`}
                style={{ color: "grey" }}
              >
                (what is this?)
              </span>
            </Popover>
          }
          style={{ width: "100%" }}
        >
          {isOldTreatmentPlan ? (
            <>
              <Flex vertical gap={10}>
                <Typography>
                  This might be an old note. Would you like your Nudge assistant
                  to generate a treatment plan?
                </Typography>
                <Flex>
                  <Button
                    type="primary"
                    onClick={handleGenerateNewTreatmentPlan}
                  >
                    Generate
                  </Button>
                </Flex>
              </Flex>
            </>
          ) : (
            <>
              {!sessionTreatmentPlanContent ? (
                <Flex align="center">
                  <LoadingOutlined />
                  <Typography
                    style={{
                      marginLeft: "10px",
                      fontWeight: "bold",
                      color: "#606060",
                    }}
                  >
                    Your Nudge assistant is generating a treatment plan
                  </Typography>
                </Flex>
              ) : (
                <Typography style={{ whiteSpace: "pre-line" }}>
                  <Flex vertical gap={10}>
                    {!editTreatmentPlan ? (
                      <Flex>
                        {expandedSessionTreatmentPlan
                          ? sessionTreatmentPlanContent
                          : `${sessionTreatmentPlanContent
                              .split(" ")
                              .slice(0, 35)
                              .join(" ")}${
                              sessionTreatmentPlanContent.split(" ").length > 35
                                ? "..."
                                : ""
                            }`}
                      </Flex>
                    ) : (
                      <TextArea
                        className={`${
                          styles[`${componentName}__main-section-textarea}`]
                        }`}
                        value={sessionTreatmentPlanContent}
                        autoSize={{ minRows: 3, maxRows: 25 }}
                        onChange={(event) => {
                          setSessionTreatmentPlanContent(event.target.value);
                        }}
                      />
                    )}
                    <Flex gap={10}>
                      {!editTreatmentPlan && (
                        <Button
                          type={
                            expandedSessionTreatmentPlan ? "default" : "primary"
                          }
                          onClick={() => {
                            if (!expandedSessionTreatmentPlan) {
                              trackEvent(EventType.EXPAND_SESSION_PLAN, {
                                recordingId: notesDetails?.id,
                              });
                            }
                            setExpandedSessionTreatmentPlan(
                              !expandedSessionTreatmentPlan
                            );
                          }}
                        >
                          {expandedSessionTreatmentPlan
                            ? "Show Less"
                            : "Show More"}
                        </Button>
                      )}
                      {expandedSessionTreatmentPlan && (
                        <Button
                          type="default"
                          className={`${
                            styles[`${componentName}__main-section-copy-button`]
                          }`}
                          onClick={() =>
                            copySectionContent(
                              notesDetails,
                              sessionTreatmentPlanContent,
                              NoteSection.TREATMENT_PLAN
                            )
                          }
                          icon={<CopyOutlined />}
                        >
                          Copy
                        </Button>
                      )}
                      {editTreatmentPlan && !isDemoPath && (
                        <Button
                          className={`${
                            styles[`${componentName}__main-section-save-button`]
                          }`}
                          onClick={updateTreatmentPlan}
                          type="primary"
                        >
                          Save Changes
                        </Button>
                      )}
                      {!editTreatmentPlan && !isDemoPath && (
                        <Button
                          onClick={() => {
                            setEditTreatmentPlan(!editTreatmentPlan);
                          }}
                          type="primary"
                        >
                          Edit
                        </Button>
                      )}
                      {editTreatmentPlan && (
                        <Button
                          onClick={() => {
                            setEditTreatmentPlan(!editTreatmentPlan);
                            setSessionTreatmentPlanContent(
                              notesDetails?.treatment_plan as string
                            );
                          }}
                          type="primary"
                        >
                          {"Discard"}
                        </Button>
                      )}
                    </Flex>
                  </Flex>
                </Typography>
              )}
            </>
          )}
        </Card>
      </Flex>
    </Flex>
  );
};

export default NudgeTreatmentPlan;
