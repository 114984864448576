import { Card, Flex } from "antd";
import styles from "./notes.module.scss";
import NoteSectionTitle from "./NoteSectionTitle.component";
import NoteCopyButton from "./NoteCopyButton.component";
import NotesTextArea from "./NotesTextArea.component";
import SaveButton from "./SaveButton.component";
import { copySectionContent } from "../../utils/datamanipulation.utils";
import { useAppSelector } from "../../hooks/redux-hooks";
import { NoteSection } from "../../utils/analytics";

const NoteSectionEdit = ({
  value,
  title,
  field,
  analyticsField,
  bigger = false,
  handleSaveClick,
}: {
  value: string;
  title: string;
  field: string;
  bigger?: boolean;
  analyticsField: NoteSection;
  handleSaveClick: () => void;
}) => {
  const { notesDetails } = useAppSelector((state) => state.notes);
  const componentName = "notes";
  const onCopy = () => {
    if (!notesDetails) return;
    copySectionContent(notesDetails, value, analyticsField);
  };
  return (
    <Flex className={`${styles[`${componentName}__main-container`]}`}>
      <Card className={`${styles[`${componentName}__main-section`]}`} hoverable>
        <Flex justify="space-between" align="center">
          <NoteSectionTitle title={title} />
          <NoteCopyButton onClick={onCopy} />
        </Flex>
        <NotesTextArea value={value} field={field} bigger={bigger} />
        <SaveButton onClick={handleSaveClick} />
      </Card>
    </Flex>
  );
};

export default NoteSectionEdit;
