import { Button, Drawer, message } from "antd";
import React, { useEffect } from "react";
import Swal from "sweetalert2";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { useIsMobileView } from "../../hooks/ui-hook";
import {
  createClient,
  deleteClient,
  getAllClients,
  updateClient,
  validateClientName,
} from "../../service/client.service";
import {
  setCreateClientState,
  setDemoState,
  setSelectedClient,
  setShowDemoSignupModal,
} from "../../slices/appStateSlice";
import { resetClientForm, setClients } from "../../slices/clientSlice";
import { TClientForm } from "../../types/index.type";
import { sortClientsByName } from "../../utils/datamanipulation.utils";
import ClientForm from "./ClientForm.component";
import useCheckDemoPath from "../../hooks/app.hook";
import useIntercomWithData from "../../hooks/useIntercomWithData";

const CreateClient = () => {
  const dispatch = useAppDispatch();
  const isMobile = useIsMobileView();
  const checkDemoPath = useCheckDemoPath();
  const { bootWithData, shutdown } = useIntercomWithData();

  const [submissionInProgres, setSubmissionInProgres] = React.useState(false);
  const { isCreateClientDrawerVisible, selectedClient, isDemoPath, demoState } =
    useAppSelector((state) => state.appState);
  const { clientForm, isEditing } = useAppSelector((state) => state.client);
  const userInfo = useAppSelector((state) => state.auth.userInfo);
  const closeDrawer = () => {
    dispatch(setCreateClientState(false));
    dispatch(resetClientForm());
  };

  useEffect(() => {
    shutdown();

    return () => {
      if (!userInfo.is_paying || userInfo.group_name) {
        bootWithData();
      }
    };
  }, [shutdown, bootWithData, userInfo.is_paying, userInfo.group_name]);

  const saveDetailsForRetrieval = () => {
    dispatch(
      setDemoState({
        ...demoState,
        clientForm,
        isClientModalOpen: true,
      }),
    );
  };

  const submitClient = async () => {
    if (checkDemoPath()) {
      return;
    }
    setSubmissionInProgres(true);

    let clientsFromServer: TClientForm[] = [];

    // This is to fetch the latest list of clients from the server before making the POST request
    try {
      clientsFromServer = (await getAllClients()).data;
      const sortedClients = sortClientsByName(clientsFromServer);
      dispatch(setClients(sortedClients));
    } catch (error) {
      setSubmissionInProgres(false);
      message.error("Failed to add client");
      console.error(error);
    }

    const clientNameValidationResult = validateClientName(
      clientForm,
      clientsFromServer,
    );

    if (clientNameValidationResult) {
      message.warning(clientNameValidationResult);
      setSubmissionInProgres(false);
      return;
    }

    createClient({
      ...clientForm,
      name: clientForm.name.trim(),
      diagnosis_ids: clientForm.diagnosis_ids
        ? clientForm.diagnosis_ids?.map((item) => parseInt(item.toString()))
        : [],
    })
      .then(() => {
        message.success("Client added successfully");
        dispatch(setCreateClientState(false));
        dispatch(resetClientForm());
        getAllClients()
          .then((response) => {
            const sortedClients = sortClientsByName(response.data);
            dispatch(setClients(sortedClients));
          })
          .catch((error) => {
            console.error(error);
            message.error("Failed to fetch clients");
          });
      })
      .catch((error) => {
        console.error(error);
        message.error("Failed to add client");
      })
      .finally(() => {
        setSubmissionInProgres(false);
      });
  };

  const deletePatient = () => {
    if (checkDemoPath()) {
      return;
    }
    if (clientForm.id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this client.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete client!",
        cancelButtonText: "No, keep client",
      }).then((result) => {
        if (result.isConfirmed && clientForm && clientForm.id) {
          deleteClient(clientForm.id)
            .then(() => {
              message.success("Client deleted successfully");
              dispatch(setCreateClientState(false));
              dispatch(resetClientForm());
              if (selectedClient && clientForm.id === selectedClient.id) {
                dispatch(setSelectedClient(null));
              }
              getAllClients()
                .then((response) => {
                  const sortedClients = sortClientsByName(response.data);
                  dispatch(setClients(sortedClients));
                })
                .catch((error) => {
                  console.error(error);
                  message.error("Failed to fetch clients");
                });
            })
            .catch((error) => {
              console.error(error);
              message.error("Failed to delete client");
            });
        }
      });
    }
  };

  const updatePatient = () => {
    if (checkDemoPath()) {
      return;
    }
    setSubmissionInProgres(true);

    const clientNameValidationResult = validateClientName(clientForm, []);

    if (clientNameValidationResult) {
      message.warning(clientNameValidationResult);
      setSubmissionInProgres(false);
      return;
    }

    if (!clientForm.id) {
      setSubmissionInProgres(false);
      return;
    }
    updateClient(clientForm.id, {
      ...clientForm,
      name: clientForm.name.trim(),
      diagnosis_ids: clientForm.diagnosis_ids
        ? clientForm.diagnosis_ids?.map((item) => parseInt(item.toString()))
        : [],
    })
      .then(() => {
        message.success("Client updated successfully");
        dispatch(setCreateClientState(false));
        dispatch(resetClientForm());
        getAllClients()
          .then((response) => {
            const sortedClients = sortClientsByName(response.data);
            dispatch(setClients(sortedClients));
            const currentlySelectedClient = sortedClients.find(
              (client) => client.id === clientForm.id,
            );
            dispatch(setSelectedClient(currentlySelectedClient));
          })
          .catch((error) => {
            console.error(error);
            message.error("Failed to fetch clients");
          });
      })
      .catch((error) => {
        console.error(error);
        message.error("Failed to update client");
      })
      .finally(() => {
        setSubmissionInProgres(false);
      });
  };

  return (
    <Drawer
      open={isCreateClientDrawerVisible}
      title={isEditing ? "Edit Client" : "Add Client"}
      onClose={closeDrawer}
      width={500}
      destroyOnClose
      height={isMobile ? "80%" : "100%"}
      placement={isMobile ? "bottom" : "right"}
      footer={
        isEditing
          ? [
              <Button
                key="back"
                type="default"
                onClick={deletePatient}
                style={{
                  marginRight: 8,
                }}
              >
                {`Delete Client`}
              </Button>,
              <Button
                disabled={submissionInProgres}
                loading={submissionInProgres}
                key="back"
                type="primary"
                onClick={updatePatient}
              >
                {`Update Client`}
              </Button>,
            ]
          : [
              <Button
                key="back"
                disabled={submissionInProgres}
                loading={submissionInProgres}
                type="primary"
                onClick={submitClient}
              >
                {`Add Client`}
              </Button>,
            ]
      }
    >
      <ClientForm />
    </Drawer>
  );
};

export default CreateClient;
