import { Card, Flex, Input, message } from "antd";
import React, { useMemo } from "react";
import {
  extractPsychotherapyExplanation,
  getCptCodeLabelByPsychotherapyMinutes,
  getNotesType,
  NoteType,
  showAddonCptCode,
  totalTimeSpentOnPsychotherapy,
} from "../../domain/notes.domain";
import useCheckDemoPath from "../../hooks/app.hook";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { updateNote } from "../../service/notes.service";
import { setNotesDetails } from "../../slices/notesSlice";
import { EventType, NoteSection, trackEvent } from "../../utils/analytics";
import {
  copyAddonDetails
} from "../../utils/datamanipulation.utils";
import NoteCopyButton from "./NoteCopyButton.component";
import styles from "./notes.module.scss";
import NoteSectionEdit from "./NoteSectionEdit";
import NoteSectionTitle from "./NoteSectionTitle.component";
import NotesSessionInsight from "./NotesSessionInsight.component";
import NotesTextArea from "./NotesTextArea.component";
import NudgeTreatmentPlan from "./NudgeTreatmentPlan.component";
import SaveButton from "./SaveButton.component";
import SessionDetails from "./SessionDetails.component";

const SOAP_FIELDS = [
  {
    title: "Subjective",
    object: "subject",
    analyticsField: NoteSection.SUBJECTIVE,
  },
  {
    title: "Objective",
    object: "objective",
    analyticsField: NoteSection.OBJECTIVE,
  },
  {
    title: "Assessment",
    object: "assessment",
    analyticsField: NoteSection.ASSESSMENT,
  },
  {
    title: "Plan",
    object: "plan",
    analyticsField: NoteSection.PLAN,
  }
];

const DAP_FIELDS = [
  {
    title: "Data",
    object: "data",
    analyticsField: NoteSection.DATA,
  },
  {
    title: "Assessment",
    object: "assessment",
    analyticsField: NoteSection.ASSESSMENT,
  },
  {
    title: "Plan",
    object: "plan",
    analyticsField: NoteSection.PLAN,
  }
];

const NotesNiew = () => {
  const componentName = "notes";

  const dispatch = useAppDispatch();
  const checkDemoPath = useCheckDemoPath();

  const { isDemoPath } = useAppSelector((state) => state.appState);
  const { notesDetails } = useAppSelector((state) => state.notes);

  const [editedPsychotherapyMinutes, setEditedPsychotherapyMinutes] =
    React.useState(() => notesDetails?.psychotherapy_minutes || 0);

  const handleSaveClick = async () => {
    if (checkDemoPath()) {
      return;
    }
    if (notesDetails) {
      trackEvent(EventType.SAVE_NOTE, {
        recordingId: notesDetails?.id,
        noteType: getNotesType(notesDetails.note_type),
      });

      const updateNoteData = {
        audio_id: notesDetails?.id,
        data: notesDetails.data,
        subject: notesDetails.subject,
        objective: notesDetails.objective,
        assessment: notesDetails.assessment,
        plan: notesDetails.plan,
        intake_note: notesDetails.intake_note,
        eap_intake_note: notesDetails.eap_intake_note,
        psych_intake_note: notesDetails.psych_intake_note,
        psych_followup_note: notesDetails.psych_followup_note,
        emdr_note: notesDetails.emdr_note,
        sol_psych_intake_note: notesDetails.sol_psych_intake_note,
        sol_psych_followup_note: notesDetails.sol_psych_followup_note,
        sol_therapy_intake_note: notesDetails.sol_therapy_intake_note,
        sol_therapy_followup_note: notesDetails.sol_therapy_followup_note,
      };

      await updateNote(updateNoteData)
        .then((response) => {
          const { data } = response;
          dispatch(setNotesDetails(data));
          message.success("Note saved successfully");
        })
        .catch(() => {
          message.error("Error saving note");
        });
    }
  };

  const handleSaveAddon = async () => {
    const updatedData = {
      audio_id: notesDetails?.id,
      psychotherapy_minutes: editedPsychotherapyMinutes,
      psychotherapy_minutes_explanation:
        notesDetails?.psychotherapy_minutes_explanation,
    };

    await updateNote(updatedData)
      .then((response) => {
        const { data } = response;
        dispatch(setNotesDetails(data));
        message.success("Add-on information updated successfully");
      })
      .catch(() => {
        message.error("Error updating add-on information");
      });
  };

  const psychoTherapyMinutes = useMemo(() => {
    if (notesDetails?.psychotherapy_minutes) {
      return notesDetails.psychotherapy_minutes;
    }
    if (notesDetails?.sol_psych_intake_note) {
      return totalTimeSpentOnPsychotherapy(notesDetails.sol_psych_intake_note);
    }
    if (notesDetails?.sol_psych_followup_note) {
      return totalTimeSpentOnPsychotherapy(
        notesDetails?.sol_psych_followup_note
      );
    }

    return 0;
  }, [notesDetails]);

  const psychoTherapyExplanation = useMemo(() => {
    if (notesDetails?.psychotherapy_minutes_explanation) {
      return notesDetails.psychotherapy_minutes_explanation;
    }

    if (notesDetails?.sol_psych_intake_note) {
      return extractPsychotherapyExplanation(
        notesDetails.sol_psych_intake_note
      );
    }

    if (notesDetails?.sol_psych_followup_note) {
      return extractPsychotherapyExplanation(
        notesDetails.sol_psych_followup_note
      );
    }

    return "";
  }, [notesDetails]);

  const handlePsychoTherapyMinutesUpdate = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newMinutes =
      event.target.value === "" ? 0 : Number(event.target.value);
    setEditedPsychotherapyMinutes(newMinutes);
  };

  if (!notesDetails) {
    return null;
  }

  return (
    <>
      <Flex gap={10} vertical>
        <NotesSessionInsight />
      </Flex>
      {(notesDetails.note_type === NoteType.Intake ||
        notesDetails.note_type === NoteType.SOL_PSYCH_INTAKE ||
        notesDetails.note_type === NoteType.THERAPY_INTAKE) && (
        <NudgeTreatmentPlan />
      )}

      {(notesDetails?.location_type != null ||
        notesDetails?.duration_type != null) && <SessionDetails />}

      {notesDetails.note_type === NoteType.SOAP && (
        <Flex vertical gap={20}>
          {SOAP_FIELDS.map((field) => (
            <NoteSectionEdit
              title={field.title}
              field={field.object}
              analyticsField={field.analyticsField}
              handleSaveClick={handleSaveClick}
              // @ts-ignore
              value={notesDetails[field.object]}
            />
          ))}
        </Flex>
      )}
      {notesDetails.note_type === NoteType.Intake && (
        <Flex>
          {notesDetails && notesDetails.note_type === NoteType.Intake && (
            <NoteSectionEdit
              title="Intake"
              field="intake_note"
              analyticsField={NoteSection.INTAKE_NOTES}
              handleSaveClick={handleSaveClick}
              value={notesDetails.intake_note}
              bigger={true}
            />
          )}
        </Flex>
      )}
      {notesDetails.note_type === NoteType.EAP_INTAKE && (
          <Flex>
            <NoteSectionEdit
              title="EAP Intake"
              field="eap_intake_note"
              analyticsField={NoteSection.EAP_INTAKE_NOTES}
              handleSaveClick={handleSaveClick}
              value={notesDetails.eap_intake_note}
              bigger={true}
            />
          </Flex>
      )}
      {notesDetails.note_type === NoteType.PSYCHIATRIC_INTAKE && (
        <NoteSectionEdit
          title="Psychatric Intake"
          field="psych_intake_note"
          analyticsField={NoteSection.PSYCH_INTAKE_NOTES}
          handleSaveClick={handleSaveClick}
          value={notesDetails.psych_intake_note}
          bigger={true}
        />
      )}
      {notesDetails.note_type === NoteType.DAP && (
        <Flex vertical gap={20}>
          {DAP_FIELDS.map((field) => (
            <NoteSectionEdit
              title={field.title}
              field={field.object}
              analyticsField={field.analyticsField}
              handleSaveClick={handleSaveClick}
              // @ts-ignore
              value={notesDetails[field.object]}
            />
          ))}
        </Flex>
      )}
      {notesDetails && notesDetails.note_type === NoteType.EMDR && (
        <Flex vertical>
          <NoteSectionEdit
            title="EMDR"
            field="emdr_note"
            analyticsField={NoteSection.EMDR_NOTES}
            handleSaveClick={handleSaveClick}
            value={notesDetails.emdr_note}
            bigger={true}
          />
        </Flex>
      )}
      {notesDetails.note_type === NoteType.SOL_PSYCH_INTAKE && (
        <NoteSectionEdit
          title="Psychiatric Intake"
          field="sol_psych_intake_note"
          analyticsField={NoteSection.SOL_PSYCH_INTAKE_NOTE}
          handleSaveClick={handleSaveClick}
          value={notesDetails.sol_psych_intake_note || ""}
          bigger={true}
        />
      )}
      {notesDetails.note_type === NoteType.PSYCHIATRIC_FOLLOW_UP && (
        <NoteSectionEdit
          title="Psychiatric Follow-up"
          field="sol_psych_followup_note"
          analyticsField={NoteSection.SOL_THERAPY_FOLLOW_UP}
          bigger={true}
          handleSaveClick={handleSaveClick}
          value={notesDetails.sol_psych_followup_note || ""}
        />
      )}
      {notesDetails.note_type === NoteType.THERAPY_INTAKE && (
        <NoteSectionEdit
          title="Therapy Intake"
          field="sol_therapy_intake_note"
          analyticsField={NoteSection.SOL_THERAPY_INTAKE_NOTE}
          handleSaveClick={handleSaveClick}
          value={notesDetails.sol_therapy_intake_note || ""}
          bigger={true}
        />
      )}
      {notesDetails.note_type === NoteType.THERAPY_FOLLOW_UP_DAP ||
      notesDetails.note_type === NoteType.THERAPY_FOLLOW_UP_SOAP ? (
        <NoteSectionEdit
          title="Therapy Follow-up"
          field="sol_therapy_followup_note"
          analyticsField={NoteSection.SOL_THERAPY_FOLLOW_UP}
          bigger={true}
          handleSaveClick={handleSaveClick}
          value={notesDetails.sol_therapy_followup_note || ""}
        />
      ) : null}
      {notesDetails.note_type === NoteType.PSYCHIATRIC_FOLLOW_UP_SOAP ? (
        <NoteSectionEdit
          title="Psychiatric Follow-up"
          field="psych_followup_note"
          analyticsField={NoteSection.PSYCH_FOLLOW_UP}
          bigger={true}
          handleSaveClick={handleSaveClick}
          value={notesDetails.psych_followup_note || ""}
        />
      ) : null}

      {showAddonCptCode(notesDetails) ? (
        <Flex
          gap={10}
          vertical
          style={{
            marginTop: "20px",
          }}
        >
          <Flex vertical gap={20}>
            <Flex>
              <Card
                className={`${styles[`${componentName}__main-section`]}`}
                hoverable
              >
                <Flex justify="space-between" align="center">
                  <NoteSectionTitle title="Psychotherapy Add-on:" />
                  <NoteCopyButton
                    onClick={() => copyAddonDetails(notesDetails)}
                  />
                </Flex>
                <div
                  className={`${
                    styles[`${componentName}__main-section-description`]
                  }`}
                >
                  <div
                    style={{
                      fontSize: 10,
                      marginBottom: 10,
                      padding: 8,
                      backgroundColor: "#fffedb",
                      borderRadius: 8,
                    }}
                  >
                    Nudge has provided an approximate estimate of the duration of psychotherapy conducted. Please confirm or adjust the duration as per your best judgement.
                  </div>
                  <Flex
                    align="flex-start"
                    className={`${
                      styles[`${componentName}__main-section-description-col`]
                    }`}
                  >
                    <span
                      className={`${
                        styles[
                          `${componentName}__main-section-description-col-label`
                        ]
                      }`}
                    >
                      <strong>CPT Code: </strong>
                    </span>{" "}
                    <span>
                      {getCptCodeLabelByPsychotherapyMinutes(
                        psychoTherapyMinutes,
                        notesDetails
                      )}
                    </span>
                  </Flex>
                  <Flex
                    align="center"
                    className={`${
                      styles[`${componentName}__main-section-description-col`]
                    }`}
                  >
                    <span
                      className={`${
                        styles[
                          `${componentName}__main-section-description-col-label`
                        ]
                      }`}
                    >
                      <strong>Psychotherapy Minutes: </strong>
                    </span>{" "}
                    <Input
                      type="number"
                      defaultValue={psychoTherapyMinutes}
                      value={editedPsychotherapyMinutes}
                      onChange={handlePsychoTherapyMinutesUpdate}
                      style={{ width: "80px", marginLeft: "8px" }}
                    />
                  </Flex>
                  <Flex
                    align="flex-start"
                    className={`${
                      styles[`${componentName}__main-section-description-col`]
                    }`}
                  >
                    <span
                      className={`${
                        styles[
                          `${componentName}__main-section-description-col-label`
                        ]
                      }`}
                    >
                      <strong>Explanation:</strong>
                    </span>{" "}
                  </Flex>
                  <NotesTextArea
                    value={psychoTherapyExplanation}
                    field="psychotherapy_minutes_explanation"
                  />
                </div>
                <SaveButton onClick={handleSaveAddon} />
              </Card>
            </Flex>
          </Flex>
        </Flex>
      ) : null}
    </>
  );
};

export default NotesNiew;